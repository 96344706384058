var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employ"
  }, [_c('div', {
    staticClass: "employ__title"
  }, [_c('div', {
    staticClass: "employ__title-options"
  }, [_c('div', {
    staticClass: "employ__title-link",
    on: {
      "click": _vm.back
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-arrow.svg"),
      "alt": ""
    }
  })]), _c('PageTitle', {
    attrs: {
      "text": "Найм работника в проект"
    }
  })], 1), _c('div', {
    staticClass: "employ__title-options"
  }, [_c('el-button', {
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Отмена ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": !_vm.selectedVacancy || _vm.isDisabled
    },
    domProps: {
      "textContent": _vm._s('Нанять')
    },
    on: {
      "click": _vm.hireEmployee
    }
  })], 1)]), _c('div', {
    staticClass: "employ__table"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.freeVacancies
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'sortable ' + _vm.sortOrder : ''
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Название проекта "), _c('svg', {
          attrs: {
            "width": "6",
            "height": "9",
            "viewBox": "0 0 6 9",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M3 5H0L3 9L6 5H3Z",
            "fill": "#A2A7BE"
          }
        }), _c('path', {
          attrs: {
            "d": "M3 4L6 4L3 0L0 4L3 4Z",
            "fill": "#A2A7BE"
          }
        })])]), _vm.projectsFilterList && _vm.projectsFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все проекты"
          },
          on: {
            "change": _vm.filterByProject
          },
          model: {
            value: _vm.selectedProject,
            callback: function callback($$v) {
              _vm.selectedProject = $$v;
            },
            expression: "selectedProject"
          }
        }, _vm._l(_vm.projectsFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.title,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employ__table-item"
        }, [_c('span', {
          staticClass: "employ__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.project.title) + " ")]), _c('br'), _c('span', {
          staticClass: "hide-sm  hide-xs"
        }, [_vm._v(" " + _vm._s(scope.row.project.address) + " ")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Активная вакансия ")]), _vm.vacanciesFilterList && _vm.vacanciesFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все проекты"
          },
          on: {
            "change": _vm.filterByVacancy
          },
          model: {
            value: _vm.selectedVacancyFilter,
            callback: function callback($$v) {
              _vm.selectedVacancyFilter = $$v;
            },
            expression: "selectedVacancyFilter"
          }
        }, _vm._l(_vm.vacanciesFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.title,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employ__table-item"
        }, [_c('span', [_vm._v(" " + _vm._s(scope.row.title) + " "), _c('div', {
          staticClass: "employ__table-info  hide-lg  hide-md",
          on: {
            "click": _vm.showVacancyDescription
          }
        }, [_vm._v(" i "), _c('div', {
          staticClass: "hide-sm  hide-xs  js-content"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--days"
        }, [_vm._v(" Дни: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.mon
          }
        }, [_vm._v("ПН")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.tue
          }
        }, [_vm._v("ВТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.wed
          }
        }, [_vm._v("СР")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.thu
          }
        }, [_vm._v("ЧТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.fri
          }
        }, [_vm._v("ПТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sat
          }
        }, [_vm._v("СБ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sun
          }
        }, [_vm._v("ВС")])])]), scope.row.workday_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Рабочее время: ")]), _c('div', {
          staticClass: "schedule-value schedule-value--active"
        }, [_vm._v(" " + _vm._s(scope.row.workday_start.substr(0, 5)) + "-" + _vm._s(scope.row.workday_end.substr(0, 5)) + " ")])]) : _vm._e(), scope.row.dinner_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Обед: ")]), _c('div', {
          staticClass: "schedule-value schedule-value--active"
        }, [_vm._v(" " + _vm._s(scope.row.dinner_start.substr(0, 5)) + "-" + _vm._s(scope.row.dinner_end.substr(0, 5)) + " ")])]) : _vm._e()])])]), _c('el-popover', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "placement": "bottom-end",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "employ__table-info",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        }, [_vm._v("i")]), _vm._t("default", [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--days"
        }, [_vm._v(" Дни: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.mon
          }
        }, [_vm._v("ПН")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.tue
          }
        }, [_vm._v("ВТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.wed
          }
        }, [_vm._v("СР")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.thu
          }
        }, [_vm._v("ЧТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.fri
          }
        }, [_vm._v("ПТ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sat
          }
        }, [_vm._v("СБ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sun
          }
        }, [_vm._v("ВС")])])]), scope.row.workday_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Рабочее время: ")]), _c('div', {
          staticClass: "schedule-value schedule-value--active"
        }, [_vm._v(" " + _vm._s(scope.row.workday_start.substr(0, 5)) + "-" + _vm._s(scope.row.workday_end.substr(0, 5)) + " ")])]) : _vm._e(), scope.row.dinner_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Обед: ")]), _c('div', {
          staticClass: "schedule-value schedule-value--active"
        }, [_vm._v(" " + _vm._s(scope.row.dinner_start.substr(0, 5)) + "-" + _vm._s(scope.row.dinner_end.substr(0, 5)) + " ")])]) : _vm._e()])])], 2)], 1)])];
      }
    }], null, true)
  }), _c('el-table-column', {
    attrs: {
      "index": 2
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employ__table-item"
        }, [scope.row.start_date ? _c('span', [_c('span', {
          staticClass: "hide-lg  hide-md"
        }, [_vm._v("н: ")]), _vm._v(" " + _vm._s(_vm._f("date")(scope.row.start_date, "datetime")) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")]), scope.row.end_date ? _c('span', {
          staticClass: "hide-lg  hide-md"
        }, [_c('br'), _vm._v(" к: " + _vm._s(_vm._f("date")(scope.row.end_date, "datetime")) + " ")]) : _vm._e()])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm  hide-xs"
  }, [_vm._v("Начало работ")]), _c('span', {
    staticClass: "hide-lg  hide-md"
  }, [_vm._v("Проект")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 3
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employ__table-item"
        }, [scope.row.end_date ? _c('span', [_vm._v(" " + _vm._s(_vm._f("date")(scope.row.end_date, "datetime")) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm  hide-xs"
  }, [_vm._v("Окончание работ")]), _c('span', {
    staticClass: "hide-lg  hide-md"
  }, [_vm._v("График")])])], 2), _c('el-table-column', {
    attrs: {
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('label', {
          staticClass: "el-checkbox",
          class: {
            'is-checked': scope.row.id === _vm.selectedVacancy
          }
        }, [_c('span', {
          staticClass: "el-checkbox__input",
          class: {
            'is-checked': scope.row.id === _vm.selectedVacancy
          }
        }, [_c('span', {
          staticClass: "el-checkbox__inner"
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.selectedVacancy,
            expression: "selectedVacancy"
          }],
          staticClass: "el-checkbox__original",
          attrs: {
            "type": "radio",
            "aria-hidden": "false"
          },
          domProps: {
            "value": scope.row.id,
            "checked": _vm._q(_vm.selectedVacancy, scope.row.id)
          },
          on: {
            "change": function change($event) {
              _vm.selectedVacancy = scope.row.id;
            }
          }
        })])])];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "employ__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }