<template>
  <div class="employ">
    <div class="employ__title">
      <div class="employ__title-options">
        <div class="employ__title-link" @click="back">
          <img src="@/assets/icons/icon-arrow.svg" alt="" />
        </div>
        <PageTitle text="Найм работника в проект" />
      </div>
      <div class="employ__title-options">
        <el-button @click="back">
          Отмена
        </el-button>
        <el-button
          type="primary"
          :disabled="!selectedVacancy || isDisabled"
          @click="hireEmployee"
          v-text="'Нанять'"
        />
      </div>
    </div>
    <div class="employ__table">
      <el-table :data="freeVacancies" @header-click="sortData">
        <el-table-column
          :index="0"
          :label-class-name="
            typeSort === 'title' ? 'sortable ' + sortOrder : ''
          "
        >
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Название проекта
              <svg
                width="6"
                height="9"
                viewBox="0 0 6 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
                <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
              </svg>
            </div>
            <el-select
              v-if="projectsFilterList && projectsFilterList.length"
              v-model="selectedProject"
              filterable
              placeholder="Все проекты"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByProject"
            >
              <el-option
                v-for="item in projectsFilterList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employ__table-item">
              <span class="employ__table-item--bold">
                {{ scope.row.project.title }}
              </span>
              <br />
              <span class="hide-sm  hide-xs">
                {{ scope.row.project.address }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Активная вакансия
            </div>
            <el-select
              v-if="vacanciesFilterList && vacanciesFilterList.length"
              v-model="selectedVacancyFilter"
              filterable
              placeholder="Все проекты"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByVacancy"
            >
              <el-option
                v-for="item in vacanciesFilterList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employ__table-item">
              <span>
                {{ scope.row.title }}
                <div
                  @click="showVacancyDescription"
                  class="employ__table-info  hide-lg  hide-md"
                >
                  i
                  <div class="hide-sm  hide-xs  js-content">
                    <div class="schedule">
                      <div class="schedule-row">
                        <div class="schedule-title schedule-title--days">
                          Дни:
                        </div>
                        <div class="schedule-value">
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.mon
                            }"
                            >ПН</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.tue
                            }"
                            >ВТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.wed
                            }"
                            >СР</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.thu
                            }"
                            >ЧТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.fri
                            }"
                            >ПТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.sat
                            }"
                            >СБ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.sun
                            }"
                            >ВС</span
                          >
                        </div>
                      </div>
                      <div v-if="scope.row.workday_start" class="schedule-row">
                        <div class="schedule-title schedule-title--time">
                          Рабочее время:
                        </div>
                        <div class="schedule-value schedule-value--active">
                          {{ scope.row.workday_start.substr(0, 5) }}-{{
                            scope.row.workday_end.substr(0, 5)
                          }}
                        </div>
                      </div>
                      <div v-if="scope.row.dinner_start" class="schedule-row">
                        <div class="schedule-title schedule-title--time">
                          Обед:
                        </div>
                        <div class="schedule-value schedule-value--active">
                          {{ scope.row.dinner_start.substr(0, 5) }}-{{
                            scope.row.dinner_end.substr(0, 5)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <el-popover
                  placement="bottom-end"
                  trigger="hover"
                  class="hide-sm  hide-xs"
                >
                  <span slot="reference" class="employ__table-info">i</span>
                  <slot>
                    <div class="schedule">
                      <div class="schedule-row">
                        <div class="schedule-title schedule-title--days">
                          Дни:
                        </div>
                        <div class="schedule-value">
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.mon
                            }"
                            >ПН</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.tue
                            }"
                            >ВТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.wed
                            }"
                            >СР</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.thu
                            }"
                            >ЧТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.fri
                            }"
                            >ПТ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.sat
                            }"
                            >СБ</span
                          >
                          <span
                            :class="{
                              'schedule-value--active': scope.row.workweek.sun
                            }"
                            >ВС</span
                          >
                        </div>
                      </div>
                      <div v-if="scope.row.workday_start" class="schedule-row">
                        <div class="schedule-title schedule-title--time">
                          Рабочее время:
                        </div>
                        <div class="schedule-value schedule-value--active">
                          {{ scope.row.workday_start.substr(0, 5) }}-{{
                            scope.row.workday_end.substr(0, 5)
                          }}
                        </div>
                      </div>
                      <div v-if="scope.row.dinner_start" class="schedule-row">
                        <div class="schedule-title schedule-title--time">
                          Обед:
                        </div>
                        <div class="schedule-value schedule-value--active">
                          {{ scope.row.dinner_start.substr(0, 5) }}-{{
                            scope.row.dinner_end.substr(0, 5)
                          }}
                        </div>
                      </div>
                    </div>
                  </slot>
                </el-popover>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="2">
          <template slot="header">
            <span class="hide-sm  hide-xs">Начало работ</span>
            <span class="hide-lg  hide-md">Проект</span>
          </template>
          <template slot-scope="scope">
            <div class="employ__table-item">
              <span v-if="scope.row.start_date">
                <span class="hide-lg  hide-md">н: </span>
                {{ scope.row.start_date | date("datetime") }}
              </span>
              <span v-else class="color-muted">&ndash;</span>
              <span v-if="scope.row.end_date" class="hide-lg  hide-md">
                <br />
                к: {{ scope.row.end_date | date("datetime") }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="3">
          <template slot="header">
            <span class="hide-sm  hide-xs">Окончание работ</span>
            <span class="hide-lg  hide-md">График</span>
          </template>
          <template slot-scope="scope">
            <div class="employ__table-item">
              <span v-if="scope.row.end_date">
                {{ scope.row.end_date | date("datetime") }}
              </span>
              <span v-else class="color-muted">&ndash;</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <label
              class="el-checkbox"
              :class="{ 'is-checked': scope.row.id === selectedVacancy }"
            >
              <span
                class="el-checkbox__input"
                :class="{
                  'is-checked': scope.row.id === selectedVacancy
                }"
              >
                <span class="el-checkbox__inner"></span>
                <input
                  type="radio"
                  aria-hidden="false"
                  class="el-checkbox__original"
                  :value="scope.row.id"
                  v-model="selectedVacancy"
                />
              </span>
            </label>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="employ__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import Pagination from "../components/blocks/Pagination";

export default {
  name: "HireForProject",
  components: {
    PageTitle,
    Pagination
  },
  data() {
    return {
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 10,
      selectedProject: "",
      selectedVacancyFilter: "",
      selectedVacancy: null,
      isDisabled: false
    };
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    ready() {
      return this.$store.state.Auth.ready;
    },
    freeVacancies() {
      return this.$store.state.Employee.freeVacancies;
    },
    pagination() {
      return this.$store.state.Employee.freeVacanciesPagination;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    projectsListAll() {
      return this.$store.state.Employee.projectsListAll;
    },
    projectsFilterList() {
      let arr = [];
      if (this.projectsListAll) {
        arr = JSON.parse(JSON.stringify(this.projectsListAll));
        arr.unshift({ id: "", title: "Все проекты" });
      }
      return arr;
    },
    vacanciesListAll() {
      return this.$store.state.Employee.vacanciesListAll;
    },
    vacanciesFilterList() {
      let arr = [];
      if (this.vacanciesListAll) {
        arr = JSON.parse(JSON.stringify(this.vacanciesListAll));
        arr.unshift({ id: "", title: "Все вакансии" });
      }
      return arr;
    }
  },
  watch: {
    ready(val) {
      if (val) {
        this.getFiltersLists();
        this.getFreeVacancies();
      }
    }
  },
  created() {
    // Делать запрос на список Projects и
    // фильтры только, если данные пользователя получены и обработаны
    if (this.ready) {
      this.getFiltersLists();
      this.getFreeVacancies();
    }
  },
  destroyed() {
    this.$store.commit("Employee/setData", {
      label: "freeVacancies",
      data: null
    });
  },
  methods: {
    showVacancyDescription(e) {
      this.$confirm(
        `<div class="remove-message">
				<img src="${require("@/assets/icons/icon-info-blue.svg")}" alt="Описание вакансии:">
				<div>
				  <div class="remove-message__title">
					Описание вакансии:
				  </div>
				  <div class="remove-message__text">
					${e.currentTarget.querySelector(".js-content").innerHTML}
				  </div>
				</div>
			  </div>`,
        {
          customClass: "remove-message__box  remove-message__box--warning",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Понятно",
          showClose: false
        }
      );
    },
    getFreeVacancies() {
      this.$store.dispatch("Employee/getFreeVacancies", {
        employee_id: this.$route.query.worker,
        params: {
          page: this.page,
          pageCount: this.pageCount,
          sortField: this.typeSort ? this.typeSort : null,
          sort: this.typeSort ? this.sortOrder : null,
          project: this.selectedProject ? this.selectedProject : null,
          vacancy: this.selectedVacancyFilter
            ? this.selectedVacancyFilter
            : null
        }
      });
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          this.typeSort === "title"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "title";
          break;
      }
      this.getFreeVacancies();
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.getFreeVacancies();
    },
    hireEmployee() {
      this.isDisabled = true;
      const data = {
        worker_id: this.$route.query.worker
      };
      this.$store
        .dispatch("Projects/hireEmployee", {
          data,
          id: this.selectedVacancy
        })
        .then(() => {
          this.$notify({
            title: "Успех",
            message: "Сотрудник нанят",
            type: "success"
          });
          this.isDisabled = false;
          this.back();
        })
        .catch(err => {
          console.error(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
          this.isDisabled = false;
        });
    },
    getFiltersLists() {
      this.$store.dispatch("Employee/getProjectsAll", {
        company: this.$route.query.company
      });
      this.$store.dispatch(
        "Employee/getVacanciesAll",
        this.$route.query.worker
      );
    },
    filterByProject() {
      this.page = 1;
      this.getFreeVacancies();
    },
    filterByVacancy() {
      this.page = 1;
      this.getFreeVacancies();
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employ {
  $section: &;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    position: relative;

    &-button {
      position: absolute;
      right: 0;
      bottom: -5rem;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }

    &-options {
      display: flex;
      align-items: center;
    }
  }

  &__table {
    .el-table th {
      padding: 0;
      vertical-align: top;

      .cell {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $text;

        @at-root {
          #{$section} .disabled-row & {
            color: inherit;
          }
        }
      }
    }

    &-info {
      display: inline-block;
      vertical-align: top;
      margin: 5px 0 -5px;
      height: 1.6rem;
      width: 1.6rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z' fill='%2331333B'/%3E%3Cpath d='M8.93 6.58789L6.64 6.87489L6.558 7.25489L7.008 7.33789C7.302 7.40789 7.36 7.51389 7.296 7.80689L6.558 11.2749C6.364 12.1719 6.663 12.5939 7.366 12.5939C7.911 12.5939 8.544 12.3419 8.831 11.9959L8.919 11.5799C8.719 11.7559 8.427 11.8259 8.233 11.8259C7.958 11.8259 7.858 11.6329 7.929 11.2929L8.93 6.58789Z' fill='%2331333B'/%3E%3Cpath d='M8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z' fill='%2331333B'/%3E%3C/svg%3E");
      font-size: 0;
      line-height: 0;
    }
  }
}

.schedule {
  padding: 0 0.9rem;

  &-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
    font-size: 1.2rem;
    line-height: 1.6rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-title {
    font-weight: 700;
    color: #2f3138;

    &--days {
      min-width: 4.2rem;
    }

    &--time {
      min-width: 10.2rem;
    }
  }

  &-value {
    color: $form-def-placeholder-color;

    &--active {
      color: $text;
    }

    span {
      margin: 0 0.5rem;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .employ__title {
    flex-wrap: wrap;
  }
  .employ__title-options {
    width: 100%;

    + .employ__title-options {
      margin-top: 12px;
    }

    .el-button {
      flex-grow: 1;
    }
  }
  .employ {
    .employ__table {
      margin: 0 -16px;

      .cell {
        display: block;
      }
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        &:nth-child(3),
        &:nth-child(4) {
          border-radius: 0;
          width: calc(50% - 10px);
          margin-top: 16px;
          text-align: left;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
        &:nth-child(4) {
          width: calc(50% + 10px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(5) {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin: 0 16px 4px;
          order: -15;
          width: 100%;
          background: none;

          .cell {
            padding: 0;
          }

          .table-th--title {
            display: none;
          }
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;
        text-align: left;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: calc(50% - 10px);
          order: -40;
          font-size: 14px;
          line-height: 20px;
        }

        &:nth-child(3) {
          width: calc(50% - 10px);
          order: -35;
        }

        &:nth-child(5) {
          width: 20px;
          order: -30;
        }

        &:nth-child(2) {
          width: 100%;
          order: -25;
        }

        &:nth-child(4) {
          display: none;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
      .employ__table-actions__chat {
        position: absolute;
        right: 16px;
        bottom: 16px;
        margin: 0;

        img {
          margin: 0;
          position: static;
        }
      }
    }
  }
  .employ__table-actions {
    padding: 0;
  }
  .employ__table-info {
    margin: 3px 0 -3px;
  }
  .remove-message__text {
    .schedule {
      padding: 0;
      margin-top: 10px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .employ__title-link {
    display: none;
  }
}
</style>
